import { useState } from 'react';

import { Box, Divider, Typography, ToggleButtonGroup, ToggleButton, Button, FormControlLabel, Checkbox, Chip, Avatar } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import InfoIcon from '@mui/icons-material/Info';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListIcon from '@mui/icons-material/List';

import SearchBar from "../components/Searchbar";
import CollapsibleTable from '../components/CollapsibleTable';

const ViewButton = styled(ToggleButton)(({ theme }) => ({
    fontWeight: 700,
    width: 'fit-content',
    padding: '5px 15px',
    backgroundColor: '#fff',
    '&:hover': {
        color: '#1976d2',
    },
    "&.Mui-selected, &.Mui-selected:hover": {
        color: '#fff',
        backgroundColor: '#1976d2'
    }
}));

const TaskManage = () => {
    const [activeView, setActiveView] = useState('my_tasks');
    const [patientGoals, setPatientGoals] = useState(false);

    const handleChange = (event, newView) => {
        setActiveView(newView);
    };

    return (
        <Box sx={{ flexGrow: 1, backgroundColor: '#f2f2f2', minHeight: 'calc(100vh - 64px)' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2 }}>
                <Box sx={{ display: 'flex', width: '100%', gap: 1, alignItems: 'center' }}>
                    <Typography variant="h4" color="primary" sx={{ fontFamily: 'Roboto', lineHeight: 'normal' }}>Task Management</Typography>
                    <InfoIcon sx={{ color: '#a3a3a3', fontSize: 24 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 'auto' }}>
                        <SearchBar />
                        <FilterAltOutlinedIcon sx={{ fontSize: '28px' }} />
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', pt: 1, justifyContent: 'space-between', width: '100%' }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={activeView}
                        exclusive
                        onChange={(handleChange)}
                        aria-label="Platform"
                    >
                        <ViewButton color="primary" value="my_tasks">My Tasks</ViewButton>
                        <ViewButton value="care_team_tasks">Care Team Tasks</ViewButton>
                    </ToggleButtonGroup>
                    <Button color="primary" variant="contained" sx={{ fontWeight: 700, padding: '5px 15px' }}>Add</Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '10px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <FormControlLabel
                            sx={{ width: 'fit-content', margin: 0, borderRadius: 1, border: '1px solid #a3a3a3' }}
                            control={<Checkbox defaultChecked />}
                            label="Include Patient Goals"
                            componentsProps={{
                                typography: { fontWeight: 700, marginRight: '10px' }
                            }}
                        />
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                            <Chip
                                icon={<KeyboardArrowDownIcon />}
                                label={[`Filters`, <Avatar sx={{ width: 20, height: 20, fontSize: 12, backgroundColor: '#1976d2' }}>2</Avatar>]}
                                sx={{
                                    borderRadius: '5px',
                                    fontWeight: 700,
                                    backgroundColor: '#cbd5e1',
                                    '.MuiChip-label': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.75
                                    }
                                }} />
                            <Chip
                                icon={<ListIcon />}
                                label="Grouped By Patient"
                                sx={{
                                    borderRadius: '5px',
                                    fontWeight: 700,
                                    backgroundColor: '#cbd5e1'
                                }}
                            />
                            {/* <Button color="primary" variant="outlined" sx={{ fontWeight: 700, padding: '5px 15px' }}>Filters</Button> */}
                            {/* <Button color="primary" variant="outlined" sx={{ fontWeight: 700, padding: '5px 15px' }}>Grouped By Patient</Button> */}
                        </Box>
                    </Box>
                    <CollapsibleTable />
                </Box>
            </Box>
        </Box >
    )
}

export default TaskManage;