import * as React from 'react';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, maxWidth, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ maxWidth, theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#E4F2FF',
        color: 'rgba(0, 0, 0, 0.87)',
        maxHeight: 450,
        maxWidth: maxWidth,
        overflowY: 'auto',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const TableTooltip = ({ title, maxWidth, visible, children }) => {
    return (
        <StyledTooltip
            title={title}
            maxWidth={maxWidth}
            disableHoverListener={visible}
        >
            {children}
        </StyledTooltip>
    )
}

export default TableTooltip;