import React, {useState,useRef, forwardRef }  from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '1px',
    backgroundColor: alpha(theme.palette.common.white, 0.95),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 1),
    },
    width: 'fit-content',
    border: '1px solid #595454',
    display:'flex',
    flexDirection:'row'
    //boxShadow: '0 5px 5px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    //position: 'relative',
    //pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'#21387A'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#000',
    fontFamily:'BMSHumanity',
    fontSize:16,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        fontFamily:'BMSHumanity',
        fontSize:16,
        paddingLeft: `calc( ${theme.spacing(0.5)})`,
        //width: '20ch',
        width: '150px'
    },
    '& .MuiInputBase-input::placeholder': {
        color: '#A7A6A6', // Replace with your desired color
      },
}));

const SearchBar = forwardRef((props) => {
    const [expanded, setExpanded] = useState(false);
    const inputRef = useRef(null);
    const handleSearchIconClick = () => {
        //setExpanded(!expanded);
        inputRef.current.focus()
      };

    return (
        <Search>
            
            <StyledInputBase
                placeholder="Type last name"
                inputProps={{ 'aria-label': 'search' }}
                value={props.inputValue}
                onChange={props.handleinputchange}
                inputRef={inputRef}
                onFocus={() => {
                    inputRef.current.focus();
                  }}
                endAdornment={<SearchIconWrapper>
                    <SearchIcon sx={{color:'#FFF'}} onClick={handleSearchIconClick}/>
                </SearchIconWrapper>}
            />
            {props.filteredData?.length > 0 && (
        <span aria-live="polite" style={{ position: 'absolute', left: '-9999px', top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }}>
          {`${props.filteredData?.length} results found`}
        </span>
      )}
        </Search>
    );
})
export default SearchBar;