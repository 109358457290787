import moment from "moment";

const getShortMonth = (providedDate) => {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[providedDate.getMonth()];
}

export const formatDate = (providedDate) => {
    if (providedDate === ''  || providedDate === undefined || providedDate === null || !moment(providedDate).isValid()) return '';
    if (providedDate === 'Today') return 'Today';
    const date = new Date(providedDate);
    let day = date.getDate();
    let month = getShortMonth(date);
    const year = date.getFullYear();
    const formatted = `${month} ${day}, ${year}`;
    return  moment(providedDate).format('MMM DD, yyyy');
}

export const userData = {
    name: 'Clayton Meyer',
    task_count: 7,
    notification_count: 5
}

export const patientTableData = {
    remsPatientsHUBHeaders: [
        // {
        //     id: '1',
        //     label: 'Patient Name and DOB',
        //     align: 'left',
        //     minWidth: 150
        // },
        {
            id: 'patient_rems_status',
            label: 'Patient REMS Status',
            align: 'left',
            minWidth: 130
        },
        {
            id: 'last_shipment_date_sp_name',
            label: 'Last Shipment Date SP Name',
            align: 'left',
            minWidth: 140
        },
        {
            id: 'next_psf_eligible_date',
            label: 'Next PSF eligible date to submit',
            align: 'left',
            minWidth: 160
        },
        {
            id: 'patient_tasks',
            label: 'Task(s)',
            align: 'left',
            minWidth: 70
        },
    ],
    patient_support_hub_data: [
        {
            id: 'camzyos_status',
            label: 'MyCAMZYOS Status',
            align: 'left',
            minWidth: 120
        },
        {
            id: 'access_status',
            label: 'PA Status',
            align: 'left',
            minWidth: 115
        },
        {
            id: 'auth_status_date',
            label: 'PA Status as of',
            align: 'left',
            minWidth: 115
        },
        {
            id: 'type_of_dispense',
            label: 'Type of Dispense',
            align: 'left',
            minWidth: 80
        },
        {
            id: 'patient_hub_tasks',
            label: 'Task(s)',
            align: 'left',
            minWidth: 70
        }],
    headers: [
        {
            id: 'patient_name',
            label: 'Patient Name',
            align: 'left',
            minWidth: 150
        },
        {
            id: 'date_of_birth',
            label: 'Date of Birth',
            align: 'left',
            minWidth: 120
        },
        {
            id: 'rems_status',
            label: 'REMS Status',
            align: 'center',
            minWidth: 120
        },
        {
            id: 'psf_status',
            label: 'PSF Status',
            align: 'left',
            minWidth: 170
        },
        {
            id: 'echo_scheduled',
            label: 'Echo Scheduled',
            align: 'left',
            minWidth: 150
        },
        {
            id: 'last_echo',
            label: 'Last Echo',
            align: 'left',
            minWidth: 120
        },
        {
            id: 'hub_status',
            label: 'HUB Status',
            align: 'left',
            minWidth: 150
        }, {
            id: 'rx_pa_status',
            label: 'Rx PA Status',
            align: 'left',
            minWidth: 150
        },
        {
            id: 'last_shipment',
            label: 'Last Shipment',
            align: 'left',
            minWidth: 170
        },
        {
            id: 'tasks',
            label: 'Tasks',
            align: 'center',
            minWidth: 100
        },
        {
            id: 'alerts',
            label: 'Alerts',
            align: 'center',
            minWidth: 100
        },
        {
            id: 'calendar',
            label: 'Calendar',
            align: 'center',
            minWidth: 100
        },
        {
            id: 'observations',
            label: 'Observations',
            align: 'center',
            minWidth: 100
        },
    ],
    content: [
        {
            patient: { first_name: 'Lee',last_name: 'Theresa', invited: false, registered: true },
            date_of_birth: '11/09/1960',
            rems_status: { enrolled: true, alert: true, status: 'enrolled' },
            psf_status: {
                last: '04/05/2023',
                next: '05/05/2023',
                nextTo: '06/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: true,
                    label: 'PSF Status',
                    list: [
                        { date: '05/04/2023', status: 'PSF Received' },
                        { date: '04/04/2023', status: 'PSF Received' },
                        { date: '03/04/2023', status: 'PSF Received' }
                    ]
                }
            },
            echo_scheduled: 'Yes',
            last_echo: '05/02/2023',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'PAApproved',currentStatus:'PA Start',camzyos_status_modal:'InComplete', pa_status_modal:'PA On Hold' },
            rx_pa_status: {
                status: 'Approved',
                date: '05/01/2023',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: 'OptumRx Pharmacy',
                date: '05/07/2023',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 1,
            observations: true,
            dispense: {
                type_of_dispense: 'Commercial',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 1,
                tooltip_visible: false,
                tooltip_text: ''
            },
            phub_tasks:{
                tasks: 2,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            }
        },
        {
            patient: { first_name: 'Gratzer',last_name: 'Theresa', invited: false, registered: false },
            date_of_birth: '01/14/1953',
            rems_status: { enrolled: true, alert: true, status: 'partially_enrolled',currentStatus:'Pending PSF' },
            psf_status: {
                last: '05/07/2023',
                next: '06/07/2023',
                nextTo: '07/07/2023',
                spname: 'pharmacare',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: true,
                    label: 'PSF Status',
                    list: [
                        { date: '05/07/2023', status: 'PSF Received' },
                        { date: '04/07/2023', status: 'PSF Received' },
                        { date: '03/07/2023', status: 'PSF Received' }
                    ]
                }
            },
            echo_scheduled: 'Yes',
            last_echo: '04/17/2023',
            hub_status: { status: 'Not Enrolled', date: '02/20/2023', camzyos_status: 'not_enrolled', access_status:'PADenied',currentStatus:'PA Denied',camzyos_status_modal:'InComplete', pa_status_modal:'PA Denied' },
            rx_pa_status: {
                status: 'Approved',
                date: '04/12/2023',
                tooltip: {
                    visible: true,
                    label: 'Rx PA Status',
                    list: [
                        { date: '12/04/2023', status: 'Approved' },
                        { date: '11/04/2023', status: 'In Process' }
                    ]
                }
            },
            last_shipment: {
                location: 'CVS Pharmacy',
                date: '04/15/2023',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: true,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 0,
                tooltip_visible: false,
                tooltip_text: ''
            },
            phub_tasks:{
                tasks: 2,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Andrews',last_name: 'Jennifer', invited: true, registered: false },
            date_of_birth: '04/22/1974',
            rems_status: { enrolled: true, alert: false , status: 'enrolled'},
            psf_status: {
                last: '04/27/2023',
                next: '05/27/2023',
                nextTo: '06/27/2023',
                spname: 'CarePlus',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'Yes',
            last_echo: '04/05/2023',
            hub_status: { status: 'Enrolled', date: '02/15/2023', camzyos_status: 'enrolled', access_status:'PAActionRequired',camzyos_status_modal:'InComplete', pa_status_modal:'Approval' },
            rx_pa_status: {
                status: 'In Process',
                date: '05/11/2023',
                tooltip: {
                    visible: true,
                    label: 'Rx PA Status',
                    list: [
                        { date: '11/05/2023', status: 'Approved' },
                        { date: '10/05/2023', status: 'In Process' },
                        { date: '10/05/2023', status: 'In Process' }
                    ]
                }
            },
            last_shipment: {
                location: 'Walgreens Pharmacy',
                date: '04/15/2023',
                tooltip: {
                    visible: true,
                    label: 'Last Shipment',
                    list: [
                        { date: '04/15/2023', insurance: 'Insurance Covered', pharmacy: 'Walgreens', strength: ['Camzyos', <br />, '2.5mg tablets'], day_supply: 30, refill: 4 },
                        { date: '03/15/2023', insurance: 'Insurance Covered', pharmacy: 'Walgreens', strength: ['Camzyos', <br />, '2.5mg tablets'], day_supply: 30, refill: 3 },
                        { date: '02/15/2023', insurance: 'Insurance Covered', pharmacy: 'Walgreens', strength: ['Camzyos', <br />, '2.5mg tablets'], day_supply: 30, refill: 2 },
                        { date: '01/15/2023', insurance: 'Insurance Covered', pharmacy: 'Walgreens', strength: ['Camzyos', <br />, '2.5mg tablets'], day_supply: 30, refill: 1 },
                    ]
                }
            },
            tasks: 0,
            alerts: 0,
            calendar: 0,
            observations: true,
            dispense: {
                type_of_dispense: 'FTO',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 1,
                tooltip_visible: false,
                tooltip_text: ''
            },
            phub_tasks:{
                tasks: 2,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            }
        },
        {
            patient: { first_name: 'Servin',last_name: 'David', invited: false, registered: true },
            date_of_birth: '05/04/1950',
            rems_status: { enrolled: true, alert: false, status: 'partially_enrolled',currentStatus:'Pending PSF' },
            psf_status: {
                last: '05/11/2023',
                next: '06/11/2023',
                nextTo: '07/11/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'Yes',
            last_echo: '05/11/2023',
            hub_status: { status: 'Incomplete', date: '01/28/2023', camzyos_status: 'incomplete', access_status:'PAApproved',camzyos_status_modal:'InComplete', pa_status_modal:'Denied' },
            rx_pa_status: {
                status: 'Approved',
                date: '05/05/2023',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: 'BMS PAF',
                date: '07/10/2023',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 1,
            calendar: 0,
            observations: true,
            dispense: {
                type_of_dispense: 'Gap',
                dispense_info_visible: true,
                tooltip_text:'Up to 5 days Emergency supply'
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 1,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },

            phub_tasks:{
                tasks: 2,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            }
        },
        {
            patient: { first_name: 'Habibi',last_name: 'Norah', invited: false, registered: false },
            date_of_birth: '06/29/1981',
            rems_status: { enrolled: true, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealApproved',camzyos_status_modal:'InComplete', pa_status_modal:'PA Start' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Replacement',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 2,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 0,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'InComplete', pa_status_modal:'PA Approved' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 2,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 1,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'PA Approved' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 2,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 0,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'Appeal Approved' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 1,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 0,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'Appeal On Hold' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 1,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 1,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'Appeal In Progress' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 0,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'PA In Progress' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 0,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'Appeal Denied' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 1,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'Appeal Start' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 1,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'InComplete', pa_status_modal:'Appeal Start' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 2,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'InComplete', pa_status_modal:'Appeal Start' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 2,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'InComplete', pa_status_modal:'Appeal Start' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 2,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied', camzyos_status_modal:'InComplete', pa_status_modal:'Appeal Denied' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 2,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied', camzyos_status_modal:'Enrolled', pa_status_modal:'PA Approved' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 0,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied', camzyos_status_modal:'Enrolled', pa_status_modal:'Appeal In Progress' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 0,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied', camzyos_status_modal:'Enrolled', pa_status_modal:'PA In Progress' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 0,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied', camzyos_status_modal:'Enrolled', pa_status_modal:'Appeal On Hold' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 1,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'PA Start' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 1,
                tooltip_visible: false,
                tooltip_text: ''
            }
        },
        {
            patient: { first_name: 'Moore',last_name: 'Theodore', invited: false, registered: true },
            date_of_birth: '12/15/1952',
            rems_status: { enrolled: false, alert: false, status: 'enrolled' },
            psf_status: {
                last: '05/05/2023',
                next: '06/05/2023',
                nextTo: '07/05/2023',
                spname: 'OptumRx',
                number: '+1 800-334-1243',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            echo_scheduled: 'No',
            last_echo: '',
            hub_status: { status: 'Enrolled', date: '04/05/2023', camzyos_status: 'enrolled', access_status:'AppealDenied',camzyos_status_modal:'Enrolled', pa_status_modal:'Denied' },
            rx_pa_status: {
                status: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            last_shipment: {
                location: '',
                date: '',
                tooltip: {
                    visible: false,
                    label: '',
                    list: []
                }
            },
            tasks: 1,
            alerts: 0,
            calendar: 0,
            observations: false,
            dispense: {
                type_of_dispense: 'Bridge',
                dispense_info_visible: false,
                tooltip_text:''
            },
            auth_status_date: '05/07/2023',
            rems_tasks:{
                tasks: 3,
                tooltip_visible: true,
                tooltip_text: 'Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor...'
            },
            phub_tasks:{
                tasks: 1,
                tooltip_visible: false,
                tooltip_text: ''
            }
        }
    ]
}

export const tasksTableData = {
    headers: {
        label_1: 'Patient Name',
        label_2: 'Priority',
        label_3: 'Created By',
        label_4: 'Source',
        label_5: 'Start Date',
        label_6: 'Due Date',
        label_7: 'Status'
    },
    content: [
        {
            patient: {
                name: 'George Owens',
                task_count: 3,
                tasks: [
                    {
                        title: 'Patient Forms',
                        description: ['Visit ', <a href="https://account.convermymeds.com">https://account.convermymeds.com</a>, ' to enroll the patient, confirm initial echocardiogram and authroize by completing and submitting the Patient Enrollment Form'],
                        priority: 'High',
                        created_by: 'BMS Provider1',
                        source: 'Task Manager',
                        start_date: '05/10/2023',
                        due_date: '05/17/2023',
                        status: 'complete'
                    },
                    {
                        title: 'Patient Forms',
                        description: ['Complete & Submit Patient Status Form (no later than 3 calendar days after the last of the week that echocardigram was due). ', <br />, <a href="https://camzyosrems.bms.com/prescriber/s/login">https://camzyosrems.bms.com/prescriber/s/login</a>],
                        priority: 'Med',
                        created_by: 'BMS Provider1',
                        source: 'Task Manager',
                        start_date: '06/12/2023',
                        due_date: '06/19/2023',
                        status: 'open'
                    },
                    {
                        title: 'Patient Forms',
                        description: 'Reminder: Check clinical status and ECHO in 1 week (it has been 3 weeks since last ECHO)',
                        priority: 'Med',
                        created_by: 'BMS Provider1',
                        source: 'Task Manager',
                        start_date: 'Today',
                        due_date: '',
                        status: 'open'
                    },
                ]
            },
        },
        {
            patient: {
                name: 'Harrison David',
                task_count: 3,
                tasks: [
                    {
                        title: 'Patient Forms',
                        description: ['Complete & Submit Patient Status Form (no later than 3 calendar days after the last of the week that echocardigram was due). ', <br />, <a href="https://camzyosrems.bms.com/prescriber/s/login">https://camzyosrems.bms.com/prescriber/s/login</a>],
                        priority: 'Med',
                        created_by: 'BMS Provider1',
                        source: 'Task Manager',
                        start_date: '05/16/2023',
                        due_date: '05/23/2023',
                        status: 'complete'
                    },
                    {
                        title: 'Patient Forms',
                        description: ['Visit ', <a href="https://account.convermymeds.com">https://account.convermymeds.com</a>, ' to enroll the patient, confirm initial echocardiogram and authroize by completing and submitting the Patient Enrollment Form'],
                        priority: 'High',
                        created_by: 'BMS Provider1',
                        source: 'Task Manager',
                        start_date: '04/04/2023',
                        due_date: '04/11/2023',
                        status: 'complete'
                    },
                    {
                        title: 'Patient Forms',
                        description: 'Attention: LVOT result is < 20 mmHg. Review titration-schedule for this patient.',
                        priority: 'High',
                        created_by: 'BMS Provider1',
                        source: 'Task Manager',
                        start_date: 'Today',
                        due_date: 'Today',
                        status: 'open'
                    },
                ]
            },
        },
        {
            patient: {
                name: 'Theresa Leeland',
                task_count: 1,
                tasks: [
                    {
                        title: 'Patient Forms',
                        description: ['Visit ', <a href="https://account.convermymeds.com">https://account.convermymeds.com</a>, ' to enroll the patient, confirm initial echocardiogram and authroize by completing and submitting the Patient Enrollment Form'],
                        priority: 'High',
                        created_by: 'BMS Provider1',
                        source: 'Task Manager',
                        start_date: '05/10/2023',
                        due_date: '05/17/2023',
                        status: 'complete'
                    },
                ]
            },
        },
    ]
}

export const StatusArray=   [
    {
        "title":"Pending-PSF",
        "message":`PSF is Eligible to Submit: Please visit <a   href='#'  style="color:#BE2BBB">Please visit REMS Portal</a>  to complete necessary actions and submit PSF.`,
        "link":'https://camzyosrems.bms.com/prescriber/s/login/?ec=302&startURL=%2Fprescriber%2Fs%2F',
        "isTitleNeeded": true
    },
    {
        "title":"Overdue-PSF",
        "message":`<p>PSF is Overdue: <a   href='#'  style="color:#BE2BBB">Please visit REMS Portal</a> for more info and to complete action.</p>`,
        "link":'https://camzyosrems.bms.com/prescriber/s/login/?ec=302&startURL=%2Fprescriber%2Fs%2F',
        "isTitleNeeded": true
    },
    {
        "title":"Incomplete",
        "message":`Enrollment form is missing info. Complete within <a href='#' style="color:#BE2BBB">CoverMyMeds Portal</a>.`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": true
    },
    {
        "title":"PA On Hold",
        "message":`<b>PA Action Required</b> – PA currently on-hold. If ready to resume, please reach out to CMM to complete action`,
        "isTitleNeeded": false
    },
    {
        "title":"PA Denied",
        "link":'https://account.covermymeds.com/login',
        "message":`<b>PA Action Required</b> - Appeal may be needed. If PA was submitted through CMM, visit <a href='#' style="color:#BE2BBB">CoverMyMeds Portal</a> for more info and to complete action. Otherwise, please follow up with patient’s payer directly.​`,
        "isTitleNeeded": false
    },
    {
        "title":"Approval",
        "message":"<b>PA Action Required</b>  Denied  Appeal Action Required",
        "link":'',
        "isTitleNeeded": false
    },
    {
        "title":"Denied",
        "message":"<b>PA Action Required</b>  Denied  Appeal Action Required",
        "link":'',
        "isTitleNeeded": false
    },
    {
        "title":"PA Start",
        "message":`<b>PA Action Required</b> - Visit <a href='#' style="color:#BE2BBB">CoverMyMeds Portal</a> for more info and to complete action. Otherwise, please follow up with patients payer directly.`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": false
    },
    {
        "title":"Appeal On Hold",
        "message":`<b>Appeal Action Required</b> - Appeal currently on-hold. If ready to resume, visit <a href='#' style="color:#BE2BBB">CoverMyMeds Portal</a> for more info and to complete action Otherwise, please follow up with patient’s payer directly`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": false
    },
    {
        "title":"Appeal Denied",
        "message":`<b>Appeal Action Required</b> - Appeal may be needed. If PA was submitted through CMM, visit <a href='#' style="color:#BE2BBB">CoverMyMeds Portal</a> for more info and to complete action. Otherwise, please follow up with patients payer directly.`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": false
    },
    {
        "title":"Appeal Start",
        "message":`<b>Appeal Action Required</b> - Appeal may be needed. If PA was submitted through CMM, visit <a href='#' style="color:#BE2BBB">CoverMyMeds Portal</a> for more info and to complete action. Otherwise, please follow up with patients payer directly.`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": false
    },
    {
        "title":"PA Unconfirmed",
        "message":`<b>PA Action Required</b> – PA currently on-hold. If ready to resume, please reach out to CMM to complete action`,
        "isTitleNeeded": false
    }
]

export const StatusArrayForExcel=   [
    {
        "title":"Pending-PSF",
        "message":`PSF is Eligible to Submit: Please visit Please visit REMS Portal to complete necessary actions and submit PSF.`,
        "link":'https://camzyosrems.bms.com/prescriber/s/login/?ec=302&startURL=%2Fprescriber%2Fs%2F',
        "isTitleNeeded": true
    },
    {
        "title":"Overdue-PSF",
        "message":`PSF is Overdue: Please visit REMS Portal for more info and to complete action.`,
        "link":'https://camzyosrems.bms.com/prescriber/s/login/?ec=302&startURL=%2Fprescriber%2Fs%2F',
        "isTitleNeeded": true
    },
    {
        "title":"Incomplete",
        "message":`Enrollment form is missing info. Complete within CoverMyMeds Portal.`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": true
    },
    {
        "title":"PA On Hold",
        "message":`PA Action Required – PA currently on-hold. If ready to resume, please reach out to CMM to complete action`,
        "isTitleNeeded": false
    },
    {
        "title":"PA Denied",
        "link":'https://account.covermymeds.com/login',
        "message":`PA Action Required - Appeal may be needed. If PA was submitted through CMM, visit CoverMyMeds Portal for more info and to complete action. Otherwise, please follow up with patient’s payer directly.​`,
        "isTitleNeeded": false
    },
    {
        "title":"Approval",
        "message":"PA Action Required Denied Appeal Action Required",
        "link":'',
        "isTitleNeeded": false
    },
    {
        "title":"Denied",
        "message":"PA Action Required Denied  Appeal Action Required",
        "link":'',
        "isTitleNeeded": false
    },
    {
        "title":"PA Start",
        "message":`PA Action Required - Visit CoverMyMeds Portal for more info and to complete action. Otherwise, please follow up with patients payer directly.`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": false
    },
    {
        "title":"Appeal On Hold",
        "message":`Appeal Action Required - Appeal currently on-hold. If ready to resume, visit CoverMyMeds Portal for more info and to complete action Otherwise, please follow up with patient’s payer directly`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": false
    },
    {
        "title":"Appeal Denied",
        "message":`Appeal Action Required - Appeal may be needed. If PA was submitted through CMM, visit CoverMyMeds Portal for more info and to complete action. Otherwise, please follow up with patients payer directly.`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": false
    },
    {
        "title":"Appeal Start",
        "message":`Appeal Action Required - Appeal may be needed. If PA was submitted through CMM, visit CoverMyMeds Portal for more info and to complete action. Otherwise, please follow up with patients payer directly.`,
        "link":'https://account.covermymeds.com/login',
        "isTitleNeeded": false
    },
    {
        "title":"PA Unconfirmed",
        "message":`PA Action Required – PA currently on-hold. If ready to resume, please reach out to CMM to complete action`,
        "isTitleNeeded": false
    }
]