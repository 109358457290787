import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { userData } from '../utils';

import { AppBar, Box, Toolbar, IconButton, Typography, Badge, MenuItem, Menu, Button, Link } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VideocamIcon from '@mui/icons-material/Videocam';
import AssignmentIcon from '@mui/icons-material/Assignment';

import Logo from '../assets/images/logo.png';

const Header = (props) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={17} color="error">
                        <AssignmentIcon />
                    </Badge>
                </IconButton>
                <p>AssignmentIcon</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }} >
            <AppBar color='default' position="fixed" sx={{
                width: { sm: `calc(100% - ${props.drawerWidth}px)` },
                ml: { sm: `${props.drawerWidth}px` },
                backgroundColor: '#f5f5f5'
            }}>
                <Toolbar sx={{ padding: '5px' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={props.handleDrawerToggle}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img style={{ display: 'flex', height: '2.5rem' }} src={Logo} />
                    <Box sx={{ flexGrow: 1 }} />
                    <Button href="tel:1-800-721-5072" color="primary" variant="outlined" sx={{ position: 'relative', padding: '3px', paddingLeft: '35px', border: "1px solid #1976d2", borderRadius: "20px" }}>
                        <Box sx={{ display: 'flex', position: 'absolute', left: -1, backgroundColor: "#1976d2", padding: 1, borderRadius: '50%' }}>
                            <PhoneIcon sx={{ color: "#fff" }} />
                        </Box>
                        <Box sx={{ display: 'flex', padding: '0 10px', flexDirection: 'column' }}>
                            <Typography component="span" variant="subtitle2" color="#525252" sx={{ lineHeight: 'normal' }}>
                                Adverse Event?
                            </Typography>
                            <Typography component="span" variant="button" sx={{ lineHeight: 'normal' }}>
                                1-800-721-5072
                            </Typography>
                        </Box>
                    </Button>
                    <Box sx={{ width: '2px', height: "40px", borderRadius: "20px", margin: "0 10px", backgroundColor: "#525252" }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="tasks"
                            color="inherit"
                            sx={{
                                padding: "8px"
                            }}
                            onClick={() => navigate('/tasks')}
                        >
                            <Badge badgeContent={userData.task_count} color="primary">
                                <AssignmentIcon sx={{ color: '#525252', fontSize: 36 }} />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="tasks"
                            color="inherit"
                            sx={{
                                padding: "8px"
                            }}
                        >
                            <Badge badgeContent={userData.notification_count} color="primary">
                                <CrisisAlertIcon sx={{ color: '#525252', fontSize: 36 }} />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="tasks"
                            color="inherit"
                            sx={{
                                padding: "8px"
                            }}
                            disabled
                        >
                            {/* <Badge badgeContent={5} color="primary"> */}
                            <VideocamIcon sx={{ fontSize: 36 }} />
                            {/* </Badge> */}
                        </IconButton>

                    </Box>
                    <Box sx={{ width: '2px', height: "40px", borderRadius: "20px", margin: "0 10px", backgroundColor: "#525252" }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccountCircleIcon sx={{ color: '#525252', fontSize: "46px" }} />
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h6" sx={{ lineHeight: 'normal', fontFamily: 'Roboto', fontWeight: 600, color: '#1976d2', lineHeight: 'normal' }}>{userData.name}</Typography>
                            <Typography variant="subtitle1" sx={{ lineHeight: 'normal', fontFamily: 'Roboto', lineHeight: 'normal' }}>Sign Out</Typography>
                        </Box>
                    </Box>
                </Toolbar >
            </AppBar >
            {renderMobileMenu}
            {renderMenu}
        </Box >
    );
}

export default Header;