import React from 'react';
import { Box, Typography } from '@mui/material';

const ACS = () => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: '#f2f2f2',
            minHeight: '100vh'
        }}>
            <Typography variant="h6">SAML Response Endpoint</Typography>
        </Box>
    )
}

export default ACS;