import React, { useEffect } from "react";

function generateState(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return result;
}

const state = generateState(32);

//For Dev environment
    
const redirect_uri = "https://camzyoshcpdashboard-dev.web-dev.bms.com"; // Website URL
const client_id = "3pj62ep3aotlejdsmlq323j24s"; // App client ID for Cognito
const identity_provider = "camzyosHcpDashboard-OIDC-New-dev"; // "camzyosHcpOidcCognitoIDP-dev";
const base_url = "https://camzyoshcpcogniauth-userpool-dev.auth.us-east-1.amazoncognito.com"; //Cognito Userpool Domain configured

//const cognitoAuthorizationURL = `${process.env.BMS_CAMZYOS_BASE_URL}/oauth2/authorize?response_type=code&client_id=${process.env.BMS_CAMZYOS_CLIENT_ID}&redirect_uri=${process.env.BMS_CAMZYOS_REDIRECT_URI}&identity_provider=${process.env.BMS_CAMZYOS_IDENTITY_PROVIDER}`;
const cognitoAuthorizationURL = `${base_url}/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&identity_provider=${identity_provider}`;

const APIGWURL = 'https://cognitocamzyosnew-token-validate-dev.web-dev.bms.com'; //APIGateway URL for tokens, validation against userpool.
// const APIGWURL_UserInfo = 'https://cognitocamzyosnew-token-validate-dev.web-dev.bms.com/path1';
const xAPIKey = "fzyTOZLoe34NMdZ5QyRcvaksxnmI3XgzsGIROdU2";



//For UAT environment

/*
const redirect_uri = "https://camzyoshcpdashboard-uat.web-dev.bms.com"; // Website URL
const client_id = "21e59t4k21nlruleodbub9fj52"; // App client ID for Cognito
const identity_provider = "camzyosHcpDashboard-OIDC-New-uat"; // "camzyosHcpOidcCognitoIDP-dev";
const base_url = "https://camzyoshcpcogniauth-userpool-uat.auth.us-east-1.amazoncognito.com"; //Cognito Userpool Domain configured

//const cognitoAuthorizationURL = `${process.env.BMS_CAMZYOS_BASE_URL}/oauth2/authorize?response_type=code&client_id=${process.env.BMS_CAMZYOS_CLIENT_ID}&redirect_uri=${process.env.BMS_CAMZYOS_REDIRECT_URI}&identity_provider=${process.env.BMS_CAMZYOS_IDENTITY_PROVIDER}`;
const cognitoAuthorizationURL = `${base_url}/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&identity_provider=${identity_provider}`;

const APIGWURL = 'https://cognitocamzyosnew-token-validate-uat.web-dev.bms.com'; //APIGateway URL for tokens, validation against userpool.
// const APIGWURL_UserInfo = 'https://cognitocamzyosnew-token-validate-dev.web-dev.bms.com/path1';
const xAPIKey = "ncxT8kKRFD6claFMTqVgu8Rf0IjF0dhv8zqlsmfA";
*/


const LogoutURL = `${base_url}/logout?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}`;
const Logout = () => {
  useEffect(()=>{
    fetch(LogoutURL)
    .then((res) => res.json())
    .then((json) => {
      console.log('json', json)
    });
  },[])
  
  console.log();
  return (
    <div align="center" style={{ marginTop: "5.5%" }}>
      <iframe
        style={{ display: "none" }}
        src={LogoutURL}
        title="empty"
        height="0%"
        width="0%"
        sandbox=""
      ></iframe>
      {/* <iframe
        style={{ display: "none" }}
        src="https://smusxath.bms.com/logoff/index.cgi"
        title="empty"
        height="0%"
        width="0%"
        sandbox=""
      ></iframe> */}
      <div
        style={{
          minHeight: "300px",
          fontSize: "20px",
          marginTop: "10%",
        }}
      >
        You are successfully logged out. Please click{" "}
        <a href={cognitoAuthorizationURL}>here</a> to Login.
      </div>
    </div>
  );
};

export default Logout;
