import { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SelectInput = (props) => {
    const [val, setVal] = useState(props.data);

    const statusInputs = [
        { value: 'open', label: 'Open' },
        { value: 'inprogress', label: 'In Progress' },
        { value: 'complete', label: 'Complete' }
    ]

    const handleChange = (ev) => {
        // setVal(ev.target.value);
    }

    return (
        <FormControl sx={{ minWidth: 120, borderRadius: '8px' }} size="small">
            <Select
                id="select_input"
                value={val}
                onChange={handleChange}
                sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                }}
                placeholder="Select..."
            >
                {statusInputs.map(item => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectInput;