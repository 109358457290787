import React from 'react';
import './Circle.css'; // Import the CSS file for styling
const Circle = ({ number, type,handleEvent }) => {
  return (
    number === 0 ? (
      <div className='dotteddiv'><span className="dottedLine">{'--'}</span></div>
    ):(
      <div tabIndex={0} aria-label="Tasks" className={type == "REMS" ? "rems_circle" : type == "PHUB" ? "phub_circle" : "circle"} onClick={handleEvent}><span className="number">{number}</span></div>
    )
  );
};
export default Circle;