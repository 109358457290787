import { useState } from 'react';
import { formatDate } from '../utils';
import { tasksTableData } from '../utils';

import { Box, Collapse, IconButton, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Paper, Chip } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HistoryIcon from '@mui/icons-material/History';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import AddBoxIcon from '@mui/icons-material/AddBox';

import SelectInput from './SelectInput';

const priorityChip = (priority) => {
    switch (priority) {
        case 'High': return (<Chip label={priority} color="error" sx={{ fontWeight: 700, borderRadius: '5px' }} />)
        case 'Med': case 'High': return (<Chip label={priority} sx={{ backgroundColor: '#fde047', fontWeight: 700, borderRadius: '5px' }} />)
        case 'Low': case 'High': return (<Chip label={priority} color="success" sx={{ fontWeight: 700, borderRadius: '5px' }} />)
    }
}

const Row = (props) => {
    const { row, headers } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ minWidth: 50, fontWeight: 700, padding: '5px 10px', bgcolor: '#f2f2f2' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left" sx={{ width: '100%', minWidth: 200, fontWeight: 700, padding: '5px 10px', bgcolor: '#f2f2f2' }}>{row.patient.name + " (" + row.patient.task_count + ")"}</TableCell>
                <TableCell align="left" sx={{ minWidth: 100, fontWeight: 700, padding: '5px 10px', color: '#1976d2', bgcolor: '#f2f2f2' }}>{headers.label_2}</TableCell>
                <TableCell align="left" sx={{ minWidth: 120, fontWeight: 700, padding: '5px 10px', color: '#1976d2', bgcolor: '#f2f2f2' }}>{headers.label_3}</TableCell>
                <TableCell align="left" sx={{ minWidth: 120, fontWeight: 700, padding: '5px 10px', color: '#1976d2', bgcolor: '#f2f2f2' }}>{headers.label_4}</TableCell>
                <TableCell align="left" sx={{ minWidth: 120, fontWeight: 700, padding: '5px 10px', color: '#1976d2', bgcolor: '#f2f2f2' }}>{headers.label_5}</TableCell>
                <TableCell align="left" sx={{ minWidth: 120, fontWeight: 700, padding: '5px 10px', color: '#1976d2', bgcolor: '#f2f2f2' }}>{headers.label_6}</TableCell>
                <TableCell align="left" sx={{ minWidth: 200, fontWeight: 700, padding: '5px 10px', color: '#1976d2', bgcolor: '#f2f2f2' }}>{headers.label_7}</TableCell>
                <TableCell align="left" sx={{ minWidth: 50, fontWeight: 700, padding: '5px 10px', color: '#1976d2', bgcolor: '#f2f2f2' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        <AddBoxIcon sx={{ color: '#1976d2' }} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0, borderBottom: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small">
                                {row.patient.tasks.map(task => (
                                    <TableRow>
                                        <TableCell sx={{ minWidth: 50 }}></TableCell>
                                        <TableCell align="left" sx={{ width: '100%', minWidth: 200, padding: '5px 10px' }}>
                                            <Box sx={task.status === 'complete' && { textDecoration: 'line-through', color: '#737373' }}>
                                                <Typography variant="h6">{task.title}</Typography>
                                                <Typography variant="body2" sx={task.status === 'complete' ? { '& > a': { textDecoration: 'none', pointerEvents: 'none', color: '#737373' } } : { '& > a': { textDecoration: 'none', color: '#000' }, '& > a:hover': { color: '#1976d2' } }}>{task.description}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left" sx={{ minWidth: 100, padding: '5px 10px' }}>
                                            {priorityChip(task.priority)}
                                        </TableCell>
                                        <TableCell align="left" sx={{ minWidth: 120, padding: '5px 10px' }}>
                                            <Typography variant="body2">{task.created_by}</Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ minWidth: 120, padding: '5px 10px' }}>
                                            <Typography variant="body2">{task.source}</Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ minWidth: 120, padding: '5px 10px' }}>
                                            <Typography variant="body2">{formatDate(task.start_date)}</Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ minWidth: 120, padding: '5px 10px' }}>
                                            <Typography variant="body2">{formatDate(task.due_date)}</Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ minWidth: 200, padding: '5px 10px' }}>
                                            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                                <SelectInput data={task.status} />
                                                <HistoryIcon sx={{ color: '#525252' }} />
                                                <NoteAltIcon sx={{ color: '#525252' }} />
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left" sx={{ minWidth: 50, padding: '5px 10px' }}></TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const CollapsibleTable = () => {
    return (
        <Paper elevation={0} sx={{ borderRadius: 0, width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table
                    sx={{ minWidth: 650 }}
                    aria-labelledby="sticky table"
                    size={'small'}
                >
                    <TableBody>
                        {tasksTableData.content.map((item, index) => (
                            <Row key={index} row={item} headers={tasksTableData.headers} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default CollapsibleTable;