import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, Button, Grid, Modal } from "@mui/material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "25%",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  // p: 4,
};
export default function LeavingPopup({
  open,
  setOpen,
  showProceed,
  title,
  description,
  showLeavePopupUrlText,
}) {
  const [data, setData] = React.useState([]);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        onBackdropClick={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={{...style,width:{xs:'50%',md:'50%',lg:'25%'}}}>
            <div style={{ padding: "24px" }}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div style={{ padding: "24px" }}>
              <Typography
                style={{
                  fontFamily: "BMSHumanity",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "28px",
                  textAlign: "center",
                }}
              >
                {title}
              </Typography>
              <Typography
                style={{
                  fontFamily: "BMSHumanity",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "28px",
                  textAlign: "center",
                }}
              >
                {description}
              </Typography>
              <div style={{ textAlign: "center" }}>
                {showProceed && (
                  <Button
                    style={{
                      color: "#fff",
                      backgroundColor: "#BE2BBB",
                      borderRadius: "50px",
                      textTransform: "capitalize",
                      width: "40%",
                      padding: "13px 23px 13px 23px",
                    }}
                    onClick={() => {
                      window.open(showLeavePopupUrlText, "_blank");
                      setOpen(false);
                    }}
                  >
                    Proceed
                  </Button>
                )}
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </React.Fragment>
  );
}
