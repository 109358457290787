import { Box, Typography } from '@mui/material';
import { ReactComponent as Enrolled } from '../assets/images/enrolled.svg';
import { ReactComponent as Action_Required } from '../assets/images/action_required.svg';
import { ReactComponent as NotEnrolled } from '../assets/images/not_enrolled.svg';
import { ReactComponent as PartiallyEnrolled } from '../assets/images/partially_enrolled_new.svg';
import { ReactComponent as PAUnconfirmed } from '../assets/images/pa_unconfirmed.svg';
import { ReactComponent as Deactivated } from '../assets/images/Deactivated.svg';
import { ReactComponent as Overdue_PSF } from '../assets/images/Overdue-PSF.svg';
import { ReactComponent as Pending_PSF } from '../assets/images/Pending-PSF.svg';
import { ReactComponent as Appeal_Start } from '../assets/images/appeal_start.svg';
import { ReactComponent as PA_Start } from '../assets/images/pa_start.svg';
import { ReactComponent as PA_Denied } from '../assets/images/pa_denied.svg';


const Status = ({ type }) => {
    switch (type?.toLowerCase()) {

        case 'enrolled': return (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#DBFFE9', width:'80px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Enrolled width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: '25.6px', fontFamily: 'BMSHumanity',fontSize:16, color:'#006F0B', padding:'0px', width:'70px' }}>{'Enrolled'}</Typography>
                        </Box>
                    );
        case 'pa approved': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#DBFFE9', width:'90px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Enrolled width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#006F0B', padding:'0px' }}>PA Approved</Typography>
                        </Box>
        );
        case 'partiallyenrolled': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'80px', justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <PartiallyEnrolled  width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>Partially Enrolled</Typography>
                        </Box>
        );
        case 'notenrolled': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'80px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <NotEnrolled width={'100%'}/>
                            <Typography variant="body2" sx={{ width:'100%',lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#CB540E', padding:'0px' }}>Not Enrolled</Typography>
                        </Box>
        );
        case 'pa denied': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FFE5E5', width:'100px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <PA_Denied width={'30%'}/>
                            <Typography variant="body2" sx={{ width:'90px',lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#FF0000', padding:'0px' }}>PA Denied</Typography>
                        </Box>
        );
        case 'pa action Required': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'90px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
            <Action_Required width={'100%'}/>
            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>PA Action Required</Typography>
        </Box>
        );
        case 'incomplete': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'100px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            {/* <InComplete width={'100%'}/> */}
                            <PartiallyEnrolled width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>{'Incomplete'}</Typography>
                        </Box>
        );
        case 'appeal approved': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#DBFFE9', width:'90px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Enrolled width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#006F0B', padding:'0px' }}>Appeal Approved</Typography>
                        </Box>
        );
        case 'appeal denied': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'80px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Action_Required width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>Appeal Denied</Typography>
                        </Box>
        );
        case 'appeal in progress': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'110px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <PartiallyEnrolled width={'30px'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>Appeal in Progress</Typography>
                        </Box>
        );
        case 'appeal action required': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'100px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Action_Required width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>Appeal Action Required</Typography>
                        </Box>
        );
        case 'deactivated': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FFE5E5', width:'110px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Deactivated width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#FF0000', padding:'0px' }}>{'Deactivated'}</Typography>
                        </Box>
        );
        case 'pending-psf': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'115px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Pending_PSF width={'30px'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>{'Pending-PSF'}</Typography>
                        </Box>
        );
        case 'overdue-psf': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'120px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Overdue_PSF width={'30px'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>{'Overdue-PSF'}</Typography>
                        </Box>
        );
        case 'active': return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#DBFFE9', width:'70px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                <Enrolled width={'100%'}/>
                <Typography variant="body2" sx={{ lineHeight: '25.6px', fontFamily: 'BMSHumanity',fontSize:16, color:'#006F0B', padding:'0px', width:'70px' }}>{'Active'}</Typography>
            </Box>
        );
        case 'pa in progress': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'90px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <PartiallyEnrolled width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>PA In-progress</Typography>
                        </Box>
        );
        case 'pa on hold': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'75px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Action_Required width={'50%'}/>
                            <Typography variant="body2" sx={{width:'65px', lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>PA On-hold</Typography>
                        </Box>
        );
        case 'appeal on hold': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'100px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Action_Required width={'50%'}/>
                            <Typography variant="body2" sx={{width:'90px', lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>Appeal On-hold</Typography>
                        </Box>
        );
        case 'appeal start': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#DBFFE9', width:'115px',justifyContent:'center',padding:'0px 10px 0px 0px'  }}>
                            <Appeal_Start width={'30%'}/>
                            <Typography variant="body2" sx={{width:'105px', lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#006F0B', padding:'0px' }}>Appeal Start</Typography>
                        </Box>
        );
        case 'pa start': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#DBFFE9', width:'90px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <PA_Start width={'50%'}/>
                            <Typography variant="body2" sx={{width:'80px', lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#006F0B', padding:'0px' }}>PA Start</Typography>
                        </Box>
        );
        case 'pa unconfirmed': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',backgroundColor:'#FEFFBF', width:'105px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <PAUnconfirmed width={'100%'}/> 
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px'}}>PA Unconfirmed</Typography>
                        </Box>
        );
        case 'approved': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#DBFFE9', width:'90px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Enrolled width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#006F0B', padding:'0px' }}>Approved</Typography>
                        </Box>
        );
        case 'denied': return(
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#FEFFBF', width:'80px',justifyContent:'center',padding:'0px 10px 0px 0px' }}>
                            <Action_Required width={'100%'}/>
                            <Typography variant="body2" sx={{ lineHeight: 'normal', fontFamily: 'BMSHumanity',fontSize:16, color:'#C74902', padding:'0px' }}>Denied</Typography>
                        </Box>
        );
    }
};
export default Status;