import { useEffect, useState } from 'react';
import { HashRouter, Routes, Route, Outlet, useNavigate, useLocation, Link } from "react-router-dom";
import './App.css';
import { useIdleTimer } from 'react-idle-timer'

import Home from './containers/Home';
import Dashboard from './containers/Dashboard';
import TaskManage from './containers/TaskManage';
import ACS from './containers/ACS';
import { ReactComponent as Camzyos_Logo } from './assets/images/camzyos_logo.svg';

import { ReactComponent as Help_Icon } from './assets/images/help_icon.svg';
import { ReactComponent as Profile_Icon } from './assets/images/profile_icon.svg';
import { ReactComponent as Down_Arrow } from './assets/images/down-arrow.svg';
import Header from './components/Header';

import { Box, CssBaseline, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';
import SpeedIcon from '@mui/icons-material/Speed';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import BoyIcon from '@mui/icons-material/Boy';
import LayersIcon from '@mui/icons-material/Layers';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Help from './containers/Help';
import Logout from './containers/Logout';
import Cookies from "js-cookie";
import Loader from './components/Loader';
import ApiService, {logoutUrl, API_URL} from "./utils/ApiService";
import {StatusArray} from "./utils";

const idleTimer=null;
const theme = createTheme();

const AppLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
  }, [])


  const drawerWidth = 70;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List component="nav" disablePadding>
      <Toolbar />
      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />
      <ListItemButton
        selected={location?.pathname === '/dashboard'}
        onClick={() => navigate('/dashboard')}
        sx={{
          py: 2,
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: 'rgba(0,0,0,0.3)'
          },
          justifyContent: 'center'
        }}
      >
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <SpeedIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton
        selected={location?.pathname === '/tasks'}
        onClick={() => navigate('/tasks')}
        sx={{
          py: 2,
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: 'rgba(0,0,0,0.3)'
          },
          justifyContent: 'center'
        }}
      >
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <AssignmentIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton
        sx={{
          py: 2,
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: 'rgba(0,0,0,0.3)'
          },
          justifyContent: 'center'
        }}>
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <CalendarTodayIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton sx={{
        py: 2,
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: 'rgba(0,0,0,0.3)'
        },
        justifyContent: 'center'
      }}>
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <CrisisAlertIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton sx={{
        py: 2,
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: 'rgba(0,0,0,0.3)'
        },
        justifyContent: 'center'
      }}>
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <BoyIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton sx={{
        py: 2,
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: 'rgba(0,0,0,0.3)'
        },
        justifyContent: 'center'
      }}>
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <LayersIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton sx={{
        py: 2,
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: 'rgba(0,0,0,0.3)'
        },
        justifyContent: 'center'
      }}>
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <Diversity3Icon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton sx={{
        py: 2,
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: 'rgba(0,0,0,0.3)'
        },
        justifyContent: 'center'
      }}>
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <BarChartIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton sx={{
        py: 2,
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: 'rgba(0,0,0,0.3)'
        },
        justifyContent: 'center'
      }}>
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <SettingsIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

      <Divider component="li" sx={{ borderColor: '#d1d5db' }} />

      <ListItemButton sx={{
        py: 2,
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: 'rgba(0,0,0,0.3)'
        },
        justifyContent: 'center'
      }}>
        <ListItemIcon sx={{ mx: 'auto', justifyContent: 'center' }}>
          <SupportAgentIcon sx={{ height: '32px', width: '32px', color: '#fff' }} />
        </ListItemIcon>
      </ListItemButton>

    </List >
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="App">
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{ display: { xs: 'none', sm: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#1976d2' }, }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box >
    </div>
  );
};

const App = () => {
  const [userName, setuserName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPatientLoading, setIsPatientLoading] = useState(true);
  const [hcpList,sethcpList]=useState([]);
  const [patientList,setpatientList]=useState([]);
  const [messagesList,setmessagesList]=useState(StatusArray);
  const [user_id,setUser_id]=useState('');
  const [is_hide_patient_data,setIs_Hide_Patient_Data]=useState(false);
  const [isLogoutMenuOpen,setisLogoutMenuOpen]=useState(false);
  const handleClose = () => {
    setisLogoutMenuOpen(prevState => !prevState);
    setAnchorEl(null);
  };
  const user_dataa = localStorage.getItem("user_data");
  const [user_data,setUser_data]=useState(user_dataa);
  useEffect(()=>{
    let userID = localStorage.getItem("userID");
    if(userID !== undefined && userID !== "undefined" && userID)
    {
        setuserName(userID)
    }
    else
    {
        setuserName('')
    }
    
  if(user_data)
    {
      const user_iddd = user_data["custom:user_id"];
      setUser_id(user_iddd)
      }
},[user_data]);
const getHCPList = async (user_id)=>{
  
      await ApiService.commonApiFunc(API_URL+ '/getHcpList','POST',
        {
          "userId": user_id
        }
      ).then((Res)=>{
        setIsLoading(false);
        sethcpList(Res);
      })
      .catch((err)=>{
        setIsLoading(false);
      })
    //}
}

const handleOnAction= (event) =>{
  console.log('user did something', event)
}

const handleOnActive = (event) => {
  console.log('user is active', event)
}

const handleOnIdle =(event) =>{
  console.log('user is idle', event)
  setIs_Hide_Patient_Data(true);
  setTimeout(() => {
    //alert('Your session has been logged out due to inactivity.');
    handleLogout();
  }, 2000);
  idleTimerApp.pause();
}

const handleClick = (event) => {
  setisLogoutMenuOpen(prevState => !prevState);
  setAnchorEl(event.currentTarget);
};

const idleTimerApp = useIdleTimer({
  timeout: 1000 * 60 * 15,
  onIdle: handleOnIdle,
  onActive: handleOnActive,
  onAction: handleOnAction,
  debounce: 500
})

const handleLogout = () => {
    // Add your logout logic here
    const id_token = localStorage.getItem("id_token");
    document.cookie = 'cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      Cookies.remove("CloudFront-Signature", {
      path: "/",
      domain: `.${window.location.hostname}`,
    });
    Cookies.remove("CloudFront-Policy", {
      path: "/",
      domain: `.${window.location.hostname}`,
    });
    Cookies.remove("_dt", {
      path: "/",
      domain: `.${window.location.hostname}`,
    });
    Cookies.remove("CloudFront-Key-Pair-Id", {
      path: "/",
      domain: `.${window.location.hostname}`,
    });
    Cookies.remove("SM_USER", {
      path: "/",
      domain: `.${window.location.hostname}`,
    }); 
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('isLoggedIn', false);
    caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
    });
    document.cookie.split(';').forEach((cookie) => {
      const cookieName = cookie.split('=')[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
      handleClose();
      setTimeout(() => {
        ApiService.performLogout(id_token)
      }, 2000);
  };

  return (
    <ThemeProvider theme={theme}>
    <HashRouter>

   
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
    <Box sx={{backgroundColor:'#FFFFFF',position: 'sticky', top: 0,left:0, zIndex:999, width:'100%'}}>
            <Box sx={{ display:'flex',backgroundColor:'#FFF', flexDirection:'row', justifyContent:'space-between', marginLeft:'40px', marginRight:'40px'}}>
                
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', justifyItems:'center',padding:1}}>
                    <Camzyos_Logo aria-label="Camzyos Logo" />
                    <div style={{ borderLeft: '1px solid black', height: '70%', margin:'10px 10px' }}></div>
                    <Typography component="h1" color="#595454" sx={{ fontFamily: 'BMSHumanity', color:'#BE2BBB', lineHeight: 'normal', fontSize:26,alignSelf:'center', textAlign:'center', marginLeft:'5px' }}>CAMZYOS Dashboard</Typography>
                </Box>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', justifyItems:'center', padding:1}}>
                    <Box sx={{display:'flex', flexDirection:'row', padding:1}}>
                        <Typography  color="#595454" sx={{ fontFamily: 'BMSHumanity', lineHeight: '22px', fontSize:16,alignSelf:'center', textAlign:'center', marginLeft:'5px' }}>To report an adverse event, <br /> contact <strong>+1 800-721-5072</strong></Typography>
                    </Box>
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    &nbsp;
                    <Link to='/help' style={{textDecoration:'none'}}>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', padding:1}}>
                      
                        <Help_Icon />
                       
                        <Typography  color="#595454" sx={{ fontFamily: 'BMSHumanity', lineHeight: 'normal', fontSize:16,alignSelf:'center', textAlign:'center', marginLeft:'5px' }}>Help</Typography>
                    </Box>
                    </Link>

                    &nbsp;&nbsp;
                    &nbsp;
                    <button
                      onClick={handleClick}
                      aria-expanded={isLogoutMenuOpen}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 1,
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer',
                      }}
                    ><Profile_Icon /><Typography
                        color="#595454"
                        sx={{
                          fontFamily: 'BMSHumanity',
                          lineHeight: 'normal',
                          fontSize: 16,
                          alignSelf: 'center',
                          textAlign: 'center',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}
                      >
                        {userName || ''}
                      </Typography><Down_Arrow style={{ alignSelf: 'center' }} />
                      </button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        sx={{
                            marginTop: '10px', // Replace '10px' with your desired margin value
                          }}
                        MenuListProps={{
                            sx: {
                              '& .MuiMenuItem-root:first-of-type': {
                                backgroundColor: '#FFF',
                              },
                            },
                          }}
                        >
                            <MenuItem 
                                sx={{ 
                                    backgroundColor: '#FFF', 
                                    color:'#FFF',
                                    '&.Mui-selected': {
                                        backgroundColor: 'blue',
                                      }, }}
                                onClick={handleLogout}>
                                <ListItemIcon><LogoutIcon /> {/* Replace 'IconName' with the actual icon component */}
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Log Out" 
                                    primaryTypographyProps={{ style: { color: '#BE2BBB', fontFamily:'BMSHumanity',lineHeight: '32px', fontSize:16 } }}
                                    /></MenuItem>
                    </Menu>
                </Box>
            </Box>
            <hr style={{ width: '100%', borderTop: '1px solid black', margin:0 }} />
     </Box>
      <Routes>
        <Route path="/" element={<Dashboard hcpList={hcpList} patientList={patientList} messagesList={messagesList} patientLoader={isPatientLoading} is_hide_patient_data={is_hide_patient_data}/>} />
        <Route path='/help' element={<Help />}/>
        <Route path='/logout' element={<Logout />}/>
         
        <Route path="" element={<AppLayout />}>
          <Route path="/dashboard" element={<Dashboard hcpList={hcpList} patientList={patientList} messagesList={messagesList} is_hide_patient_data={is_hide_patient_data}/>} />
          <Route path="/tasks" element={<TaskManage />} />
          <Route path="/acs" element={<ACS />} />
        </Route>
      </Routes>
      </>
      )}
      </div>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
